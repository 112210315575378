<template>
 <div>
    <v-row no-gutters class="pa-1">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else class="pa-2">
            <v-toolbar dense flat class="mb-2">
                <span class="subtitle-2 text-primary">  Current Balance: {{$money(balance)}}</span>
                <v-spacer/>
                <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="From Date"
                              :value="from_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable />
                    </v-menu>
               </span>
                 <span style="width: 140px; margin-top: 24px; margin-left: 7px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, get_wallet_transaction()" />
                    </v-menu>
               </span>
               <span style="width: 140px; margin-left: 7px;">
                  <v-autocomplete
                  label="Filter"
                  dense
                  v-model="trans_type"
                  hide-details
                  outlined
                  :items="['All', 'Buy Ticket', 'Transfer Load', 'Received Load']"
                  />
               </span>
              </v-toolbar>
                
                <div id="PrintReport">
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">Date Time</th>
                          <th class="text-left">Desc.</th>
                          <th class="text-right">AMOUNT</th>
                      </tr>
                      <tr v-for="(item, index) in FILTERTRANSACTIONS" :key="index">
                          <td :class="'td-'+IS_ODD(index)">{{$moment(item.transdt).format("MMM DD, YYYY hh:mm a")}} </td>
                          <td :class="STATUS_COLOR(item.type) + ' td-' + IS_ODD(index) ">{{item.description}}</td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.amount)}}</td>
                      </tr>
                       <!-- <tr>
                          <th class="text-center lg-font" colspan="3">Tickets: {{$money(total)}} | Win: {{$money(wintotal)}}</th>
                      </tr> -->
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
      from_menu: false,
        search:'',
        from_date:'',
        trans_type: 'All',
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
       this.CHECK_BALANCE(this.user.id)
       this.from_date =  this.$moment().subtract(15, "days").format("YYYY-MM-DD")
        this.to_date =  this.$moment().format("YYYY-MM-DD")
        this.get_wallet_transaction()
        this.setDrawer(false)
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      balance(){
        return  this.$store.state.balance.balance
      },
      FILTERTRANSACTIONS(){
        var trans = []
        if(this.trans_type == 'Buy Ticket') {
           trans = this.transactions.reduce((res,item)=>{
              if(item.type == "Buy Ticket") {
                res.push(item)
              }
              return res
          }, [])
          return trans
        } else if(this.trans_type == 'Transfer Load') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.type == "Transfer Load") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        }else if(this.trans_type == 'Received Load') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.type == "Received Load") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else {
          trans = this.transactions
        }
         return trans
      },
      total(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Buy Ticket") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
       wintotal(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Win") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      get_wallet_transaction(){
        this.transactions=[]
        this.setOverlayLoading(true)
          this.$http.post("wallet/get_transactions", {account: this.user.id, from_date: this.from_date, to_date: this.to_date}).then(response => {
           response.data.items != null?this.transactions =  response.data.items:this.transactions=[]
         this.setOverlayLoading(false)
         }).catch(e => {
           console.log(e.data)
         })
      }
    },
  }
</script>